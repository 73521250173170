<template>
    <div class="discourse">
        <van-pull-refresh class="discourse-push" v-model="searchLoading" @refresh="onRefresh">
            <div>
                <van-search
                    v-model="keyword"
                    show-action
                    placeholder="请输入搜索关键词"
                    @search="onSearch"
                    @input="handleInput"
                    >
                    <template #action>
                        <div @click="onClear">
                            <van-icon name="replay" />
                        </div>
                    </template>
                </van-search>
            </div>
            <ul class="discourse-news">
                <li class="discourse-news-item" v-for="(item,index) in newsList" :key="index">
                    <div @click="sendNews(item)" class="discourse-news-content">
                        <div class="discourse-news-content-img">
                            <img :src="item.imgUrl">
                        </div>
                        <div>
                            <div>{{item.title}}</div>
                            <div class="discourse-news-content-desc">{{item.desc}}</div>
                        </div>
                    </div>
                </li>
            </ul>
            <van-collapse v-model="activeNames">
                <van-collapse-item v-for="item in list" :key="item.group_id" :disabled="item.group_discourse.length == 0" :title="item.group_name" :name="item.group_id">
                    <div slot="value"><van-badge color="#00cca2" :content="item.group_discourse_count" /></div>
                    <ul>
                        <li @click="toSend(it)" style="padding:10px 0" :class="j !=item.group_discourse.length -1?'van-hairline--bottom':''" v-for="(it,j) in item.group_discourse" :key="it.id">
                            <div v-if="it.discourse_type == 'text'">
                                <div class="discourse-nav">
                                    <img src="@/assets/send.png">
                                    <h1>{{it.discourse_name}}</h1>
                                </div>
                                <div class="discourse-text">
                                    <div class="discourse-text-content">
                                        {{it.discourse_content}}
                                    </div>
                                </div>
                            </div>

                            <div v-if="it.discourse_type == 'image'">
                                <div class="discourse-nav">
                                    <img src="@/assets/send.png">
                                    <h1>{{it.discourse_name}}</h1>
                                </div>
                                <div class="discourse-image">
                                    <img :src="it.discourse_content">
                                    <div>{{it.ext_name}}</div>
                                </div>
                            </div>

                            <div v-if="it.discourse_type == 'video'">
                                <div class="discourse-nav">
                                    <img src="@/assets/send.png">
                                    <h1>{{it.discourse_name}}</h1>
                                </div>
                                <div class="discourse-video">
                                    <div class="discourse-video-box">
                                        <van-icon class='discourse-video-icon' name="play-circle" />
                                        <img :src="`${it.discourse_content}?vframe/jpg/offset/8/w/64`">
                                    </div>
                                    <div class="discourse-video-name">{{it.ext_name}}</div>
                                </div>
                            </div>

                            <div v-if="it.discourse_type == 'file'">
                                <div class="discourse-nav">
                                    <img src="@/assets/send.png">
                                    <h1>{{it.discourse_name}}</h1>
                                </div>
                                <div class="discourse-file">
                                    <img src="@/assets/file.png">
                                    <div>{{it.ext_name}}</div>
                                </div>
                            </div>
                            
                        </li>
                    </ul>
                </van-collapse-item>
                <van-empty v-if="list.length == 0" description="暂无相关数据~" />
            </van-collapse>
        </van-pull-refresh>
        <loadings :loading='loading' />
    </div>
</template>

<script>
    import { Search,Collapse, CollapseItem,Icon,Toast,PullRefresh,Badge,Empty } from 'vant'
    import Loadings from '@/components/loading'
    import { wxWorkSend } from '@/common/wx'
    import dayjs from 'dayjs'
    export default {
        name:'discourse',
        data() {
            return {
                loading:false,
                searchLoading:false,
                keyword:'',
                activeNames:[],
                list:[],
                newsList:[]
            }
        },
        components: {
            [Icon.name]:Icon,
            [Empty.name]:Empty,
            loadings:Loadings,
            [Badge.name]:Badge,
            [Search.name]:Search,
            [Collapse.name]:Collapse,
            [PullRefresh.name]:PullRefresh,
            [CollapseItem.name]:CollapseItem,
        },
        created () {
            this.getList()
        },
        methods: {
            getList(){
                this.loading = true
                this.$api.discourse_index({keyword:this.keyword})
                .then(res => {
                    this.list = res.items
                    this.newsList = res._data.news
                    if(this.keyword){
                        let arr = []
                        this.list.forEach(item=>{
                            arr.push(item.group_id)
                        })
                        this.activeNames = arr
                    }else{
                        this.activeNames = []
                    }
                    this.loading = false
                    this.searchLoading = false
                })
                .catch(err=>{
                    this.loading = false
                    this.searchLoading = false
                })
            },
            toRefresh(item){
                this.$api.discourse_refresh({id:item.id})
                .then(res => {
                    item.media_id = res.data.media_id
                    let obj = {
                        msgtype:item.discourse_type,
                        [item.discourse_type]: {
                            mediaid:res.data.media_id,
                        },
                    }
                    wxWorkSend(obj)
                    .then(res=>{
                        this.toClick(item.id)
                    }).catch(err=>{
                        if(err.err_msg == 'sendChatMessage:cancel') return false
                        Toast.fail('发送失败')
                    })
                })
            },
            toSend(item){
                if(item.discourse_type == 'text'){
                    let obj = {
                        msgtype:"text",
                        text: {
                            content:item.discourse_content,
                        },
                    }
                    wxWorkSend(obj).then(res=>{
                        this.toClick(item.id)
                    }).catch(err=>{
                        if(err.err_msg == 'sendChatMessage:cancel') return false
                        Toast.fail('发送失败')
                    })
                }else{
                    if(item.media_id){
                        if(this.computeTime(item.media_id_updated_at)){
                            let obj = {
                                msgtype:item.discourse_type,
                                [item.discourse_type]: {
                                    mediaid:item.media_id,
                                },
                            }
                            wxWorkSend(obj).then(res=>{
                                this.toClick(item.id)
                            }).catch(err=>{
                                if(err.err_msg == 'sendChatMessage:cancel') return false
                                Toast.fail('发送失败')
                            })
                        }else{
                            this.toRefresh(item)
                        }
                    }else{
                        this.toRefresh(item)
                    }
                }
            },
            sendNews(item){
                let obj = {
                    msgtype:"news",
                    news: {
                        link: item.link, //H5消息页面url 必填
                        title: item.title, //H5消息标题
                        desc: item.desc, //H5消息摘要
                        imgUrl: item.imgUrl, //H5消息封面图片URL
                    },
                }
                wxWorkSend(obj)
                .then(res=>{
                })
                .catch(err=>{
                    if(err.err_msg == 'sendChatMessage:cancel') return false
                    Toast.fail('发送失败')
                })
            },
            computeTime(time){
                if(time){
                    if(dayjs().diff(dayjs(time), 'days') < 3){
                        return true
                    }else{
                        return false
                    }
                }else{
                    return false
                }
            },
            toClick(id){
                this.$api.discourse_click({id:id})
                .then(res=>{})
            },
            onSearch() {
                this.getList()
            },
            handleInput(){
                this.getList()
            },
            onClear(){
                this.keyword = ''
                this.getList()
            },
            onRefresh(){
                this.getList()
            }
        },
    }
</script>

<style lang="less" scoped>
    .discourse{
        height: 100vh;
        background: #fff;
        &-push{
            min-height: 100vh;
        }
        &-nav{
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img{
                width: 24px;
                margin-right: 10px;
            }
            h1{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }
        &-news{
            white-space: nowrap;
            overflow-x: scroll;
            &-item{
                display: inline-block;
                margin: 10px;
            }
            &-content{
                display: flex;
                box-shadow: 0px 0px 3px #d9d9d9;
                padding: 10px 5px;
                border-radius: 4px;
                &-img{
                    width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    img{
                        width: 28px;
                        height: 28px;
                    }
                }
                &-desc{
                    font-size: 12px;
                    color: #999;
                    width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    word-break: break-all;
                }
            }
        }
        &-text{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            &-content{
                margin-left: 34px;
            }
        }
        &-image{
            display: flex;
            align-items: center;
            img{
                height: 64px;
                margin-right: 10px;
                margin-left: 34px;
                border-radius: 2px;
            }
            div{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }
        &-video{
            display: flex;
            align-items: center;
            &-box{
                position: relative;
                margin-right: 10px;
                margin-left: 34px;
                img{
                    height: 64px;
                    border-radius: 2px;
                }
            }
            &-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                margin-left: -7px;
                margin-top: -7px;
                color: #fff;
            }
            &-name{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
            
        }
        &-file{
            display: flex;
            align-items: center;
            img{
                width: 24px;
                margin-right: 5px;
                margin-left: 34px;
            }
            div{
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                word-break: break-all;
            }
        }
    }
</style>